import { defineStore } from 'pinia'
import { FormValue } from '@/utils/types/form.type'
import { TmnData } from '@/utils/types/tmn.type'
import { LineProfile } from '@/utils/liff'
import {
  CustomerAddress,
  CustomerInformation,
} from '@/pages/user-form/UserForm'

export enum PageEnum {
  MobileForm = 1,
  OTP = 2,
  UserForm = 3,
  CompleteRegister = 4,
}

export type RegisterStore = {
  phoneNumber: FormValue<string>
  currentPage: PageEnum
  customerToken: string
  otpCode: string
  otpRefCode: string
  lineIdToken?: string
  lineProfile?: LineProfile
  createdDate?: string
  isThaiAccount: boolean
  enableSwitch: boolean
  tmnToken?: string
  tmnData?: TmnData
  customerInformation: CustomerInformation
  customerAddress: CustomerAddress
}

export type RegisterStoreKeys = keyof RegisterStore

export const useRegisterStore = defineStore('register-store', {
  state: (): RegisterStore => ({
    phoneNumber: { value: '' },
    currentPage: PageEnum.MobileForm,
    otpCode: '',
    otpRefCode: '',
    customerToken: '',
    isThaiAccount: true,
    enableSwitch: true,
    customerInformation: {} as CustomerInformation,
    customerAddress: {} as CustomerAddress,
  }),
  getters: {
    isTmnAccount(): boolean {
      return !!this.tmnData
    },
  },
  actions: {
    reset(): void {
      this.phoneNumber = { value: '' }
      this.currentPage = PageEnum.MobileForm
      this.otpCode = ''
      this.otpRefCode = ''
      this.createdDate = undefined
      this.customerInformation = {} as CustomerInformation
      this.customerAddress = {} as CustomerAddress
    },
    setPhoneNumber(newPhoneNumber: FormValue<string>): void {
      this.phoneNumber = newPhoneNumber
    },
    setCurrentPage(newCurrentPage: PageEnum): void {
      this.currentPage = newCurrentPage
    },
    hasValue(key: RegisterStoreKeys): boolean {
      switch (key) {
        case 'phoneNumber': {
          return !!this.phoneNumber.value
        }
        default: {
          return !!this[key]
        }
      }
    },
  },
  persist: true,
})
