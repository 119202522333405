<template>
  <Stepper v-if="showStepper" :current-step="registerStore.currentPage" />
  <div
    v-if="showHeader"
    class="relative bg-primary h-20 md:h-[6.5rem] 2xl:h-32 text-white font-medium flex justify-center items-center cursor-default"
  >
    <h1
      class="text-base md:text-[1.75rem] 2xl:text-4xl text-center md:leading-[42px] lg:leading-0"
      v-html="props.title"
    ></h1>
    <div
      v-if="props.showSwitchLang"
      class="absolute top-0 right-0 text-sm md:text-lg mx-4 my-2 md:mx-3 md:my-1.5"
    >
      <span
        class="mx-1"
        :class="{ 'opacity-50 cursor-pointer': locale !== 'th' }"
        @click="toggleLocale('th')"
        >TH</span
      >
      <span class="mx-1">|</span>
      <span
        class="mx-1"
        :class="{ 'opacity-50 cursor-pointer': locale !== 'en' }"
        @click="toggleLocale('en')"
        >EN</span
      >
    </div>
  </div>
  <slot class="overflow-y-auto" />
</template>
<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from '@/plugins/i18n'
import Stepper from '@/components/common/stepper/Stepper.vue'
import { useRegisterStore } from '@/stores/register.store'

const registerStore = useRegisterStore()
const router = useRouter()
const route = useRoute()
const { locale } = useI18n()

const props = withDefaults(
  defineProps<{
    title?: string
    showSwitchLang?: boolean
    showHeader?: boolean
    showStepper?: boolean
  }>(),
  {
    showSwitchLang: true,
    showHeader: true,
    showStepper: true,
  },
)

const toggleLocale = (lang: 'th' | 'en') => {
  locale.value = lang
  if (!router) return
  router.replace({ query: { ...route.query, lang } })
}
</script>
