<template>
  <div
    class="flex items-center align-middle justify-evenly w-full h-20 md:h-32"
  >
    <StepperItem
      v-for="stepDetail in state.stepDetails"
      :step-detail="stepDetail"
      :key="stepDetail.step"
      :max-step="state.stepDetails.length"
      :current-step="value"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, reactive } from 'vue'
import StepperItem from './StepperItem.vue'
import { StepDetail } from './Stepper'

const props = defineProps<{
  currentStep?: number
}>()

const state = reactive<{
  stepDetails: StepDetail[]
}>({
  stepDetails: [
    {
      label: 'stepper.enterMobile',
      step: 1,
    },
    {
      label: 'stepper.otp',
      step: 2,
    },
    {
      label: 'stepper.form',
      step: 3,
    },
    {
      label: 'stepper.success',
      step: 4,
    },
  ],
})

const value = computed(() => {
  return props.currentStep || 1
})
</script>
